@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  background-color: silver;
  font-family: 'Roboto Condensed', serif;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

button {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
}
