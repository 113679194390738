@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.account-component-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.account-container {
  margin: auto auto;
  color: #333;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 1000px;
  min-width: 1000px;
  position: relative;
}

.status-modal {
  background-color: rgba(51, 51, 51, 0.7);
  outline: 1px solid white;
  border-radius: 5px;
  color: white;
  padding: 15px;
  text-align: center;
  font-size: 1.2rem;
  position: absolute;
  top: 170px;
  width: 55%;
  z-index: 1000;
  animation: fadeInFromTop 0.5s ease-out;
}

.close-modal-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  position: absolute;
  top: 12px;
  right: 20px;
  cursor: pointer;
  box-shadow: none;
  transition: color 0.3s ease;
}

.account-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.account-details {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeInFromTop 0.5s ease-out;
  color: gray;
}

.edit-name-btn {
  cursor: pointer;
  font-size: 12px;
  text-decoration: none;
  color: white;
  background-color: #333;
  padding: 8px 12px;
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s ease;
}

.edit-name-btn:hover {
  background-color: white;
  border-color: #333;
  color: #333;
}

.name-inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
}

.name-inputs label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.name-inputs label span {
  white-space: nowrap;
}

.name-edit-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.logout-button {
  cursor: pointer;
  font-size: 18px;
  text-decoration: none;
  color: white;
  background-color: #333;
  padding: 8px 12px;
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: white;
  border-color: #333;
  color: #333;
}

.no-addresses-orders {
  color: #333;
}

.address-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  color: gray;
}

.address-item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeInFromTop 0.5s ease-out;
  transition: transform 0.3s ease;
}

.address-item:hover {
  transform: translateY(5px);
}

.default-label {
  font-size: 12px;
  color: gray;
}

.not-default-label {
  visibility: hidden;
}

.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  animation: fadeInFromTop 0.5s ease-out;
}

.modal-content {
  z-index: 1010;
}

.address-actions {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.edit-btn {
  cursor: pointer;
  font-size: 12px;
  text-decoration: none;
  color: white;
  background-color: #333;
  padding: 8px 12px;
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s ease;
}

.edit-btn:hover {
  background-color: white;
  border-color: #333;
  color: #333;
}

.delete-btn {
  background-color: red;
  color: white;
  border: 1px solid white;
  padding: 8px 12px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-btn:hover {
  background-color: white;
  color: red;
  border: 1px solid red;
  transition: background-color 0.3s ease;
}

.confirm-delete-btn {
  background-color: red;
  color: white;
  border: 1px solid white;
  padding: 10px 15px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-delete-btn:hover {
  background-color: white;
  color: red;
  border: 1px solid red;
  transition: background-color 0.3s ease;
}

.cancel-delete-btn {
  background-color: #333;
  color: white;
  border: 1px solid white;
  padding: 10px 15px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-delete-btn:hover {
  background-color: white;
  color: #333;
  border: 1px solid #333;
  transition: background-color 0.3s ease;
}

.address-to-delete {
  color: gray;
  margin: 20px;
}

.add-address-btn {
  cursor: pointer;
  font-size: 12px;
  text-decoration: none;
  color: white;
  background-color: #333;
  padding: 8px 12px;
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s ease;
}

.add-address-btn:hover {
  background-color: white;
  border-color: #333;
  color: #333;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.address-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.confirm-btn {
  cursor: pointer;
  font-size: 12px;
  text-decoration: none;
  color: white;
  background-color: #333;
  padding: 8px 12px;
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s ease;
}

.confirm-btn:hover {
  background-color: white;
  border-color: #333;
  color: #333;
}

.cancel-btn {
  cursor: pointer;
  font-size: 12px;
  text-decoration: none;
  color: white;
  background-color: red;
  padding: 8px 12px;
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s ease;
}

.cancel-btn:hover {
  background-color: white;
  border-color: red;
  color: red;
}

.order-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  color: gray;
}

.order-item {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 12px 18px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  animation: fadeInFromTop 0.5s ease-out;
  transition: transform 0.3s ease;
}

.order-item:hover {
  transform: translateY(5px);
}

.order-main-image {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.order-item-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.order-item-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.order-item-image {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.order-line-items h4 {
  margin-bottom: 5px;
}

.order-line-items p {
  margin-bottom: 5px;
}

.thumbnail-container {
  /* display: flex; */
  margin-top: 10px;
}

.thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 5px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s;
}

.thumbnail.active {
  border-color: gray;
}

.input-field {
  border: 1px solid #ccc;
  padding: 8px;
  width: 100%;
  transition: border-color 0.3s ease;
}

.input-field.error {
  border: 1px solid #ff0000;
  outline: none;
}

.error-message {
  color: #ff0000;
  font-size: 0.8rem;
}

.address-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 20px;
}

@media (max-width: 767px) {
  .account-container {
    min-width: 200px;
  }

  .account-details {
    flex-direction: column;
  }

  .edit-name-container {
    margin-top: 20px;
    align-self: flex-end;
  }

  .address-list {
    grid-template-columns: repeat(1, 1fr);
  }

  .order-list {
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
  }

  .order-item {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
  }

  .account-header h1 {
    font-size: 1.5rem;
  }

  .status-modal {
    width: 80%;
  }

  .status-modal p {
    font-size: 1rem;
  }
}

@media (max-width: 1024px) {
  .account-container {
    min-width: 200px;
  }

  .address-item:hover {
    transform: none;
  }

  .order-item:hover {
    transform: none;
  }
}
