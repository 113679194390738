@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.contact-form-container {
  min-width: 600px;
  margin: auto auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeInFromTop 0.5s ease-out;
  position: relative;
}

.form-header {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.contact-status-modal {
  background-color: rgba(51, 51, 51, 0.7);
  outline: 1px solid white;
  border-radius: 5px;
  color: white;
  padding: 15px;
  text-align: center;
  font-size: 1rem;
  position: absolute;
  top: -70px;
  width: 90%;
  z-index: 1000;
  animation: fadeInFromTop 0.5s ease-out;
}

.contact-form {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.input-field {
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

.textarea-field {
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.input-field:focus {
  border-color: #333;
  outline: none;
}

.textarea-field:focus {
  border-color: #333;
  outline: none;
}

.textarea-field {
  height: 150px;
  resize: none;
}

.submit-button {
  background-color: #333;
  color: white;
  padding: 12px;
  border: 1px solid #333;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: white;
  color: #333;
}

.submit-button:focus {
  outline: none;
}

.input-field.error-input,
.textarea-field.error-input {
  border: 1px solid red;
  margin-bottom: 5px;
}

.error {
  color: red;
  font-size: 1rem;
}

@media (max-width: 375px) {
  .contact-form-container {
    min-width: 300px;
  }

  .contact-status-modal {
    width: 80%;
    top: -50px;
  }
}

@media ((min-width: 376px) and (max-width: 767px)) {
  .contact-form-container {
    min-width: 325px;
  }

  .contact-status-modal {
    width: 80%;
  }
}
