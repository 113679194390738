@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login-container {
  min-width: 600px;
  margin: auto auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeInFromTop 0.5s ease-out;
  position: relative;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-form input {
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.login-form input:focus {
  border-color: #333;
  outline: none;
}

.login-form .error {
  color: red;
  margin-bottom: 10px;
  text-align: center;
}

.login-form input.error-input {
  border-color: red;
}

.login-form button {
  background-color: #333;
  color: white;
  padding: 12px;
  border: 1px solid #333;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: white;
  color: #333;
}

.login-form button:focus {
  outline: none;
}

.login-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
  text-align: center;
  padding: 10px;
}

.login-links a {
  color: #333;
  transition: color 0.3s ease;
}

.login-links a:hover {
  color: #666;
}

@media (max-width: 375px) {
  .login-container {
    min-width: 300px;
  }
}



@media ((min-width: 376px) and (max-width: 767px)) {
  .login-container {
    min-width: 325px;
  }
}