@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.product-list-container {
  display: flex;
  align-items: start;
  gap: 20px;
  flex-direction: column;
  margin: 20px 0;
}

.product-list-container h2 {
  margin-left: 20px;
  color: #333;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: start;
  padding: 0 20px;
}

.product-card {
  background-color: white;
  border-radius: 10px;
  padding: 16px 24px;
  width: 240px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  text-align: center;
  cursor: pointer;
  animation: fadeInFromTop 0.5s ease-out;
  transition: transform 0.3s ease;
}

.product-card:hover {
  transform: translateY(5px);
}

.product-card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  min-height: 100vh;
}

.product-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  color: gray;
  margin: 20px 0px;
}

.product-info {
  display: flex;
  justify-content: space-between;
}

.product-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-size {
  display: flex;
  align-items: center;
}

.size-select {
  appearance: none;
  background-color: transparent;
  padding-left: 5px;
  text-align: center;
  display: flex;
  gap: 5px;
}

.size-option {
  background-color: transparent;
  border: 2px solid #ccc;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.size-option.hover {
  border-color: rgba(51, 51, 51, 0.8);
}

.size-option.selected {
  border-color: white;
  background-color: #333;
  color: white;
}

.size-option:focus {
  outline: none;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
}

.modal-details h3 {
  color: gray;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  animation: fadeInFromTop 0.5s ease-out;
}

.quantity-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-top: 20px;
}

.modal-content {
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  text-align: center;
  width: 400px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1010;
}

.modal-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.modal-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.close-button {
  color: white;
  background-color: red;
  border: 1px solid white;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 24px;
  align-self: flex-end;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: white;
  color: red;
  border: 1px solid red;
}

.add-to-bag {
  background-color: #333;
  color: white;
  padding: 8px;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.add-to-bag:hover {
  background-color: white;
  color: #333;
  border: 1px solid #333;
}

.quantity-input {
  width: 50px;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.thumbnail-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 5px;
}

.thumbnail.active {
  border-color: gray;
}

@media (max-width: 767px) {
  .product-list-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .product-list {
    flex-direction: column;
  }

  .product-card {
    transition: none;
  }

  .product-info p {
    font-size: 30px;
  }

  .product-details {
    justify-content: space-between;
  }

  .modal-content {
    width: 300px;
  }

  .product-options {
    flex-direction: column;
    gap: 20px;
  }

  .quantity-container {
    padding-top: 5px;
  }

  .modal-image {
    height: 295px;
  }
}

@media (max-width: 1024px) {
  .product-card:hover {
    transform: none;
  }
}
