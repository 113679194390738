.verification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto auto;
  animation: fadeInFromTop 0.5s ease-out;
}

.verification-failed {
  color: red;
  font-size: 24px;
  text-align: center;
}
