.registration-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto auto;
  animation: fadeInFromTop 0.5s ease-out;
}

.registration-failed {
  font-size: 24px;
  text-align: center;
  color: red;
}
