.registration-pending-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto auto;
  animation: fadeInFromTop 0.5s ease-out;
}

.registration-pending-message {
  color: #333;
  font-size: 24px;
  text-align: center;
}
