.terms-conditions {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 20px;
  gap: 20px;
  margin: auto auto;
  animation: fadeInFromTop 0.5s ease-out;
  color: #333;
}

.terms-header {
  align-self: center;
  margin-bottom: 20px;
}
