@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.bag-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto auto;
  animation: fadeInFromTop 0.5s ease-out;
}

.bag-items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.bag-item-details {
  color: gray;
}

.bag-items {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  margin-bottom: 40px;
}

.bag-item {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 12px 18px;
  width: 450px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  animation: fadeInFromTop 0.5s ease-out;
  transition: transform 0.3s ease;
}

.bag-item:hover {
  transform: translateY(5px);
}

.bag-main-image {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.bag-item-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.bag-item-image {
  display: flex;
  flex-direction: column;
}

.checkout-button {
  background-color: #333;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  color: white;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.checkout-button:hover {
  background-color: white;
  color: #333;
  border: 1px solid #333;
}

.checkout-container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.total-price {
  display: flex;
  gap: 10px;
  font-size: 40px;
  color: #333;
  text-shadow: 4px 4px 9px rgba(0, 0, 0, 0.3);
}

.quantity-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.quantity-button {
  background-color: white;
  border: 2px solid gray;
  border-radius: 5px;
  padding: 5px;
  color: gray;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quantity-button:disabled {
  background-color: rgba(200, 200, 200, 0.8);
  cursor: not-allowed;
}

.quantity-button:hover {
  background-color: lightgray;
}

.remove-button {
  background-color: red;
  color: white;
  border: 1px solid white;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-button:hover {
  background-color: white;
  color: red;
  border: 1px solid red;
  transition: background-color 0.3s ease;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 90%;
  color: black;
  animation: fadeInFromTop 0.5s ease-out;
}

.modal-buttons {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-around;
}

.modal-buttons button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.modal-buttons .yes-button {
  background-color: red;
}
.modal-buttons .yes-button:hover {
  background-color: white;
  color: red;
  border: 1px solid red;
}

.modal-buttons button:hover {
  background-color: white;
  color: #333;
  border: 1px solid #333;
  transition: background-color 0.3s ease;
}

.empty-bag-message {
  font-size: 24px;
  color: #333;
}

.thumbnail-container {
  display: flex;
  margin-top: 10px;
}

.thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 5px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s;
}

.thumbnail.active {
  border-color: gray;
}

.checkout-note {
  display: flex;
  gap: 15px;
  color: #333;
  margin-bottom: 20px;
}

.star {
  color: red;
  margin-top: 3px;
}

.bag-checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 20px;
  color: #333;
}

.terms-checkbox .error {
  color: red;
}

.bag-checkbox-container a {
  color: #333;
  transition: color 0.3s ease;
}

.bag-checkbox-container a:hover {
  color: #666;
}

@media (max-width: 767px) {
  .bag-items {
    margin-bottom: 20px;
  }

  .bag-item {
    flex-direction: column;
    padding: 20px;
    width: 300px;
    text-align: center;
  }

  .bag-item:hover {
    transform: none;
  }

  .bag-item-info {
    gap: 20px;
  }

  .checkout-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .quantity-buttons {
    margin-top: 10px;
  }

  .total-price {
    margin-bottom: 10px;
  }

  .modal {
    max-width: 250px;
  }

  .quantity-button:hover {
    background-color: white;
  }

  .quantity-button:active {
    background-color: lightgray;
  }
}
