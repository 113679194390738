@import url('https://fonts.googleapis.com/css2?family=Ovo&display=swap');

.brand-text {
  font-family: 'Ovo', serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

.brand-text a {
  text-decoration: none;
  color: white;
}

.brand-text a:hover {
  color: gray;
  transition: color 0.3s ease;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: white;
}

.logo-image {
  height: 120px;
  width: auto;
  cursor: pointer;
  display: inline-block;
  margin-top: -25px;
}

.nav-button {
  font-size: 18px;
  text-decoration: none;
  color: white;
  background-color: #333;
  padding: 8px 12px;
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s ease;
}

.nav-button:hover {
  background-color: white;
  color: #333;
}

.bag-icon {
  font-size: 18px;
}

.nav-links {
  display: flex;
  gap: 20px;
  margin-right: 50px;
}

.logo-image:hover path {
  fill: gray;
  stroke: gray;
  transition: fill 0.3s ease;
}

@media (max-width: 767px) {
  .brand-text {
    display: none;
  }

  .bag-text {
    display: none;
  }
  .bag-icon {
    display: inline;
  }
}

@media (max-width: 1024px) {
  .nav-button:hover {
    background-color: #333;
    color: white;
  }

  .logo-image:hover path {
    fill: white;
    stroke: white;
  }

  .brand-text a:hover {
    color: white;
  }

  .nav-button:active {
    background-color: white;
    color: #333;
  }

  .logo-image:active path {
    fill: #333;
    stroke: #333;
  }

  .brand-text a:active {
    color: #333;
  }
}
