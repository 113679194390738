.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-top: auto;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.footer-links {
  display: flex;
  gap: 20px;
  list-style: none;
}

.footer-links a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #ccc;
}

.social-icons a {
  color: white;
  font-size: 20px;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #ccc;
}

@media (max-width: 1024px) {
  .footer-links a:hover {
    color: white;
  }

  .footer-links a:active {
    color: #333;
  }
}
