@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  min-width: 600px;
  margin: auto auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  animation: fadeInFromTop 0.5s ease-out;
  position: relative;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group input {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  text-align: center;
}

.form-group input:focus {
  border-color: #333;
  outline: none;
}

.reset-button {
  background-color: #333;
  color: white;
  padding: 12px;
  border: 1px solid #333;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reset-button:hover {
  background-color: white;
  color: #333;
}

.reset-button:focus {
  outline: none;
}

.forgot-status-modal {
  background-color: rgba(51, 51, 51, 0.7);
  outline: 1px solid white;
  border-radius: 5px;
  color: white;
  padding: 15px;
  text-align: center;
  font-size: 1rem;
  position: absolute;
  top: -70px;
  width: 90%;
  z-index: 1000;
  animation: fadeInFromTop 0.5s ease-out;
}

.form-links {
  margin-top: 15px;
  text-align: center;
}

.form-links a {
  color: #333;
  transition: color 0.3s ease;
}

.form-links a:hover {
  color: #666;
}

.form-group input.invalid {
  border: 1px solid red;
}

.error-message {
  color: #ff0000;
  font-size: 0.8rem;
}

@media (max-width: 375px) {
  .form-container {
    min-width: 200px;
  }

  .forgot-status-modal {
    width: 80%;
    top: -60px;
  }
}

@media ((min-width: 376px) and (max-width: 767px)) {
  .form-container {
    min-width: 325px;
  }

  .forgot-status-modal {
    width: 80%;
  }
}
